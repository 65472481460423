import Style from "./js/stores/style";
import Marquee from './js/marquee.js';
import { doc } from "prettier";
//const swup = new Swup({
// containers: ["#swup"],
//});

function globalInit() {
  visualViewport.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--viewport-height', `${visualViewport.height}px`);
  });

  const chkeckout_el = document.querySelector('#express-checkout-element');
  if (chkeckout_el) {

    const stripe = Stripe(chkeckout_el.dataset.key);
    const l = document.querySelector('html').dataset.locale;
    const options = {
      locale: l,
      mode: 'payment',
      amount: parseInt(chkeckout_el.dataset.price),
      currency: 'eur'
    }
    const elements = stripe.elements(options);
    const expressCheckoutElement = elements.create(
      'expressCheckout', {
      layout: {
        overflow: 'never'
      },
      paymentMethodOrder: ['applePay', 'googlePay']
    }
    )
    expressCheckoutElement.mount('#express-checkout-element')
    expressCheckoutElement.on('ready', async (event) => {
      chkeckout_el.classList.add("loaded")
    });
    expressCheckoutElement.on('confirm', async (event) => {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        const error = document.querySelector('.error-message');
        error.classList.add("active");
        error.textContent = "Error submitting payment";
        return;
      }


      // Create the PaymentIntent and obtain clientSecret
      const res = await fetch(chkeckout_el.dataset.intent, {
        method: 'POST',
      });
      const { client_secret: clientSecret } = await res.json();

      const { error } = await stripe.confirmPayment({
        // `elements` instance used to create the Express Checkout Element
        elements,
        // `clientSecret` from the created PaymentIntent
        clientSecret,
        confirmParams: {
          return_url: chkeckout_el.dataset.redirect
        },
      });

      if (error) {
        // This point is only reached if there's an immediate error when
        // confirming the payment. Show the error to your customer (for example, payment details incomplete)
        const error = document.querySelector('.error-message');
        error.classList.add("active");
        error.textContent = error.message;
      } else {
        // The payment UI automatically closes with a success animation.
        // Your customer is redirected to your `return_url`.
      }
    });
  }

  const mq = document.querySelector('.marquee');
  if (mq) {

    const m = Marquee('.marquee', 0.2)
  }
  const auto = document.querySelector(".autocomplete");
  if (auto) {
    import("./js/checkout/autocomplete.js").then((r) => {
      const autoComplete = new r.default(auto);
      autoComplete.init();
    });
  }

  document.documentElement.style.setProperty('--viewport-height', `${visualViewport.height}px`);
  const videos = document.querySelectorAll(".film-episode__video");
  if (videos.length > 0) {
    import("./js/film/video.js").then((r) => {
      videos.forEach((video) => {
        const videoInit = new r.default(video);
        videoInit.init();
      });
    });
  }

  const red_sels = document.querySelectorAll(".redirect-select");
  red_sels.forEach((sel) => {
    sel.addEventListener("change", (e) => {

      window.location = sel.value;
    });
  });


  const red = document.querySelector(".country-redirect");
  if (red) {
    red.addEventListener("click", (e) => {
      e.preventDefault();
      window.location = document.querySelector("#country-redirect").value;
    });
  }
  const header = document.querySelector("[data-js='header']");
  if (header) {
    import("./js/header.js").then((r) => {
      const headerInit = new r.default();
      headerInit.init();
    });
  }

  const headerLanguage = document.querySelector(
    "[data-js='header-language-open']",
  );
  if (headerLanguage) {
    import("./js/header-language.js").then((r) => {
      const headerLanguageInit = new r.default();
      headerLanguageInit.init();
    });
  }

  const headerSearch = document.querySelector("[data-js='header-search']");
  if (headerSearch) {
    import("./js/search.js").then((r) => {
      const headerSearchInit = new r.default();
      headerSearchInit.init();
    });
  }

  const miniCart = document.querySelector("[data-js='minicart']");
  if (miniCart) {
    import("./js/minicart/minicart.js").then((r) => {
      const minicart = new r.default();
      minicart.init();
    });
  }

  /* Popups */
  const popupCountry = document.querySelector("[data-js='popup-country']");
  if (popupCountry) {
    import("./js/popup/country.js").then((r) => {
      const popupCountryInit = new r.default(popupCountry);
      popupCountryInit.init();
    });
  }

  /* End Popups */

  // carousels

  const hero_carousels = document.querySelectorAll("[data-js='hero_carousel']");
  if (hero_carousels.length > 0) {
    import("./js/carousels/hero_carousel.js").then((r) => {
      hero_carousels.forEach((carousel) => {
        const heroCarousel = new r.default(carousel);
        heroCarousel.init();
      });
    });
  }

  const double_carousels = document.querySelectorAll(
    "[data-js='double_col_carousel']",
  );
  if (double_carousels.length > 0) {
    import("./js/carousels/double_col_carousel.js").then((r) => {
      double_carousels.forEach((carousel) => {
        const doubleCarousel = new r.default(carousel);
        doubleCarousel.init();
      });
    });
  }

  const products_carousels = document.querySelectorAll(
    "[data-js='products_carousel']",
  );
  if (products_carousels.length > 0) {
    import("./js/carousels/products_carousel.js").then((r) => {
      products_carousels.forEach((carousel) => {
        const productsCarousel = new r.default(carousel);
        productsCarousel.init();
      });
    });
  }

  const collections_carousels = document.querySelectorAll(
    "[data-js='collections_carousel']",
  );
  if (collections_carousels.length > 0) {
    import("./js/carousels/collections_carousel.js").then((r) => {
      collections_carousels.forEach((carousel) => {
        const collectionsCarousel = new r.default(carousel);
        collectionsCarousel.init();
      });
    });
  }

  const card_carousels = document.querySelectorAll("[data-js='card_carousel']");
  if (card_carousels.length > 0) {
    import("./js/carousels/card_carousel.js").then((r) => {
      card_carousels.forEach((carousel) => {
        const cardCarousel = new r.default(carousel);
        cardCarousel.init();
      });
    });
  }

  const auto_width_carousels = document.querySelectorAll(
    "[data-js='auto-width_carousel']",
  );

  if (auto_width_carousels.length > 0) {
    import("./js/carousels/auto-width_carousel.js").then((r) => {
      auto_width_carousels.forEach((carousel) => {
        const autoWidthCarousel = new r.default(carousel);
        autoWidthCarousel.init();
      });
    });
  }

  // end carousels

  /* Filters */
  const filters_carousel = document.querySelector(
    "[data-js='filters_carousel']",
  );
  if (filters_carousel) {
    import("./js/carousels/filters_carousel.js").then((r) => {
      const filtersCarousel = new r.default(filters_carousel);
      filtersCarousel.init();
    });
  }

  const listingFilters = document.querySelector("[data-js='listing-filters']");
  const listingGrid = document.querySelector("[data-js='listing-grid']");
  if (listingFilters && listingGrid) {
    import("./js/filters/layout.js").then((r) => {
      const filtersLayout = new r.default();
      filtersLayout.init();
    });
  }

  const listingControls = document.querySelector("[data-js='filter-controls']");
  if (listingControls) {
    import("./js/filters/controls.js").then((r) => {
      const filtersControls = new r.default();
      filtersControls.init();
    });
  }
  /* End Filters */

  // Quick Buy buttons

  const qbSizesForms = document.querySelectorAll(
    "[data-js='form-quick-buy-size']",
  );
  if (qbSizesForms.length > 0) {
    import("./js/quickBuy/quick-buy-sizes.js").then((r) => {
      qbSizesForms.forEach((form) => {
        const qbSizes = new r.default(form);
        qbSizes.init();
      });
    });
  }

  const qbMobileButtons = document.querySelectorAll(
    "[data-js='quick-buy-mobile-button']",
  );
  if (qbMobileButtons.length > 0) {
    import("./js/quickBuy/quick-buy-mobile.js").then((r) => {
      qbMobileButtons.forEach((button) => {
        const qbMobileButton = new r.default(button);
        qbMobileButton.init();
      });
    });
  }

  // wishlist buttons

  const wishlistButtons = document.querySelectorAll(
    "[data-js='product-wishlist-heart']",
  );
  if (wishlistButtons.length > 0) {
    import("./js/product-wishlist-heart.js").then((r) => {
      wishlistButtons.forEach((button) => {
        const wishlistHeart = new r.default(button);
        wishlistHeart.init();
      });
    });
  }

  /* Product */
  const productSizeGuide = document.querySelector(
    "[data-js='product-size-guide']",
  );
  if (productSizeGuide) {
    import("./js/product/size-guide.js").then((r) => {
      const sizeGuide = new r.default();
      sizeGuide.init();
    });
  }

  const productBuyNow = document.querySelector("[data-js='product-buy-now']");
  if (productBuyNow) {
    import("./js/product/buy-now.js").then((r) => {
      const buyNow = new r.default();
      buyNow.init();
    });
  }

  const productHowToBuy = document.querySelector(
    "[data-js='product-how-to-buy']",
  );

  if (productHowToBuy) {
    import("./js/product/how-to-buy.js").then((r) => {
      const howToBuy = new r.default();
      howToBuy.init();
    });
  }

  const productCustomize = document.querySelector(
    "[data-js='customize-checkbox']",
  );
  if (productCustomize) {
    import("./js/product/customize.js").then((r) => {
      const customize = new r.default();
      customize.init();
    });
  }

  const productGiftCard = document.querySelector(
    "[data-js='product-gift-card']",
  );
  if (productGiftCard) {
    import("./js/product/gift-card.js").then((r) => {
      const giftCard = new r.default();
      giftCard.init();
    });
  }

  const map = document.querySelector(".store-locator__single");
  if (map) {
    const data = map.dataset;
    // console.log(data)
    const myLatLng = { lat: parseFloat(data.lat), lng: parseFloat(data.lng) };
    function initMap() {
      // Styles a map in night mode.
      const map = new google.maps.Map(document.getElementById("map"), {
        center: myLatLng,
        zoom: 16,
        styles: Style,
        disableDefaultUI: true,
      });

      const mobile_map = new google.maps.Map(
        document.getElementById("map_mobile"),
        {
          center: myLatLng,
          zoom: 16,
          styles: Style,
          disableDefaultUI: true,
        },
      );

      const m = new google.maps.Marker({
        position: myLatLng,
        icon: "https://atelier-vm.s3.amazonaws.com/uploads/file_uploader/image/8/dot.png",
      });

      const m2 = new google.maps.Marker({
        position: myLatLng,
        icon: "https://atelier-vm.s3.amazonaws.com/uploads/file_uploader/image/8/dot.png",
      });

      m.setMap(map);
      m2.setMap(mobile_map);
    }

    initMap();
  }

  const productStoreFinder = document.querySelector(
    "[data-js='product-store-finder']",
  );
  if (productStoreFinder) {
    import("./js/product/store-finder.js").then((r) => {
      const storeFinder = new r.default();
      storeFinder.init();
    });
  }

  const productAtcMobile = document.querySelector("[data-js='product-atc']");
  if (productAtcMobile) {
    import("./js/product/select-variants-mobile.js").then((r) => {
      const selectVariantsMobile = new r.default();
      selectVariantsMobile.init();
    });
  }

  const atc = document.querySelector(".add-to-cart-form");
  if (atc) {
    import("./js/product/add-to-cart.js").then((r) => {
      const atcDesktop = new r.default();
      atcDesktop.init();
    });
  }

  const range = document.querySelector(".range-slider");
  if (range) {
    import("./js/filters/inputRange.js").then((r) => {
      // console.log(r)
      const inputRange = r.inputRange();
    });
  }

  /* End Product */

  //const inter = new IntersectionObs();
  //inter.init();

  /* Checkout Address */
  const differentAddressCheckbox = document.querySelector(
    '[data-js="js-open-shipping"]',
  );
  const giftCheckbox = document.querySelector('[data-js="js-open-gift"]');

  if (differentAddressCheckbox && giftCheckbox) {
    import("./js/checkout/address.js").then((r) => {
      const checkoutAddressInit = new r.default();
      checkoutAddressInit.init();
    });
  }

  /* End Checkout Address */
}
document.addEventListener("DOMContentLoaded", () => globalInit());
// Run once when page loads
// if (document.readyState === "complete") {
//  globalInit();
//} else {
// document.addEventListener("DOMContentLoaded", () => globalInit());
//}
// // Run after every additional navigation by swup
//#swup.hooks.on("page:view", () => globalInit());
